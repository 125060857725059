<template>
  <div class="main-wrapper">
    <div class="title">
      <div class="titleData">
        <span style="font-size: 14px; font-weight: bold"
          >{{ price.goods_name }}商品</span
        >
        <el-button type="primary" style="float: right" @click="addType"
          >添加脚本类型</el-button
        >
      </div>
    </div>
    <el-scrollbar ref="bar">
      <div class="no-data-icon" v-if="img == 0">
        <img
          src="../../../assets/image/no_data.png"
          alt=""
          style="margin-bottom: 10px; width: 400px"
        />
        <span class="no-data-font">暂无数据</span>
      </div>
      <!-- 商品讲解 -->
      <div class="explain" v-show="explainShow">
        <div class="explain_title">
          <div class="explain-header">
            <div
              class="explain-header-item"
              style="width: 7%; padding-left: 39px"
            >
              脚本类型
            </div>
            <div class="explain-header-item" style="width: 8.5%">动作</div>
            <div class="explain-header-item" style="width: 16%">脚本内容</div>
            <div class="explain-header-item" style="width: 14%">关键词</div>
            <div class="explain-header-item" style="width: 15%">错误关键词</div>
            <div class="explain-header-item" style="width: 12%">
              <span style="padding-left: 0.7vw">回答间隔时间</span>
            </div>
            <div class="explain-header-item" style="width: 10%"> <span style="padding-left: 0.7vw">转化率</span></div>
            <div class="explain-header-item" style="width: 10%"><span style="padding-left: 0.7vw">点击率</span></div>
            <div class="explain-header-item" style="width: 10%"><span style="padding-left: 0.7vw">流失率</span></div>
            <div
              class="explain-header-item"
              style="width: 15%; text-align: center"
            >
              操作
            </div>
          </div>
        </div>
        <!-- 商品讲解body -->
        <el-scrollbar ref="scro">
          <div class="explain-body">
            <div
              class="sum"
              v-for="(item, index) in explainList"
              :key="`explainList_${index}`"
            >
              <!-- 一级 -->
              <div class="item">
                <div
                  class="explain-body-item"
                  style="
                    width: 8%;
                    font-weight: bold;
                    padding-left: 38px;
                    box-sizing: border-box;
                    margin-right: 15px;
                  "
                >
                  商品讲解
                </div>
                <div class="explain-body-item" style="width: 7%; box-sizing: ">
                  有/无上架
                </div>
                <div
                  class="explain-body-item"
                  style="width: 15%; box-sizing: border-box"
                >
                  <el-input
                    style="width: 80%; margin-left: 5px"
                    :max="200"
                    :min="2"
                    v-model="item.script_content"
                    placeholder="请输入脚本内容"
                  ></el-input>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 12.5%; box-sizing: border-box"
                >
                  <el-input
                    style="width: 80%"
                    :max="200"
                    :min="2"
                    v-model="item.correct_keyword"
                    :disabled="disabled"
                  ></el-input>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 12.5%; box-sizing: border-box"
                >
                  <el-input
                    style="width: 80%"
                    :max="200"
                    :min="2"
                    v-model="item.wrong_keyword"
                    :disabled="disabled"
                  ></el-input>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 10%; margin-right: 30px"
                >
                  <el-input-number
                    :controls="false"
                    :precision="0"
                    style="width: 80px; margin-left: 5px"
                    :max="60"
                    :min="0"
                    v-model="item.time"
                  ></el-input-number>
                  <span style="margin-left: 5px">分</span>
                  <el-input-number
                    :controls="false"
                    :precision="0"
                    style="width: 80px; margin-left: 5px"
                    :max="60"
                    :min="0"
                    v-model="item.time1"
                  ></el-input-number>
                  <span style="margin-left: 5px">秒</span>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 9%; box-sizing: border-box; padding-right: 20px"
                >
                  <el-input
                    style="width: 80%"
                    :max="200"
                    :min="2"
                    v-model="item.change_rate"
                    placeholder="0.01-10"
                    @blur="changeInput(item.change_rate)"
                  ></el-input>
                  <span style="margin-left: 5px">%</span>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 9%; box-sizing: border-box; padding-right: 20px"
                >
                  <el-input
                    style="width: 80%"
                    :max="200"
                    :min="2"
                    v-model="item.click_rate"
                    placeholder="0.01-10"
                    @blur="clickInput(item.click_rate)"
                  ></el-input>
                  <span style="margin-left: 5px">%</span>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 9%; box-sizing: border-box; padding-right: 20px"
                >
                  <el-input
                    style="width: 80%"
                    :max="200"
                    :min="2"
                    v-model="item.lost_rate"
                    placeholder="0.01-10"
                    @blur="lostInput(item.lost_rate)"
                  ></el-input>
                  <span style="margin-left: 5px">%</span>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 11%; margin-left: 19px"
                >
                  <span
                    style="color: #4b45ff; cursor: pointer; padding-left: 15px"
                    >&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span
                  >
                  <span
                    style="color: red; cursor: pointer; padding-left: 20px"
                    @click="delLevel1(index)"
                    >删除</span
                  >
                </div>
              </div>
              <!-- 二级级三级外层 -->
              <div
                v-for="(level2item, index2) in item.children"
                :key="`level2List_${index2}`"
              >
                <!-- 二级 -->
                <div v-if="item && item.children">
                  <div class="item">
                    <div
                      class="explain-body-item"
                      style="
                        width: 8%;
                        font-weight: bold;
                        padding-left: 38px;
                        box-sizing: border-box;
                        margin-right: 15px;
                      "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="width: 8%; box-sizing: "
                    >
                      <img
                        src="../../../assets/image/subordinate.png"
                        alt=""
                        style="
                          margin-bottom: 10px;
                          width: 20px;
                          padding-left: 40px;
                        "
                      />
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 15%; box-sizing: border-box"
                    >
                      <el-input
                        style="width: 80%; margin-left: 5px"
                        :max="200"
                        :min="2"
                        v-model="level2item.script_content"
                        placeholder="请输入脚本内容"
                      ></el-input>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 12.5%; box-sizing: border-box"
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level2item.correct_keyword"
                        placeholder="请输入正确关键词"
                      ></el-input>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 12.5%; box-sizing: border-box"
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level2item.wrong_keyword"
                        placeholder="请输入错误关键词"
                      ></el-input>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 10%; margin-right: 30px"
                    >
                      <el-input-number
                        :controls="false"
                        :precision="0"
                        style="width: 80px; margin-left: 5px"
                        :max="60"
                        :min="0"
                        v-model="level2item.time"
                      ></el-input-number>
                      <span style="margin-left: 5px">分</span>
                      <el-input-number
                        :controls="false"
                        :precision="0"
                        style="width: 80px; margin-left: 5px"
                        :max="60"
                        :min="0"
                        v-model="level2item.time1"
                      ></el-input-number>
                      <span style="margin-left: 5px">秒</span>
                    </div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 9%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level2item.change_rate"
                        placeholder="0.01-10"
                        @blur="changeInput(level2item.change_rate)"
                      ></el-input>
                      <span style="margin-left: 5px">%</span>
                    </div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 9%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level2item.click_rate"
                        placeholder="0.01-10"
                        @blur="clickInput(level2item.click_rate)"
                      ></el-input>
                      <span style="margin-left: 5px">%</span>
                    </div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 9%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level2item.lost_rate"
                        placeholder="0.01-10"
                        @blur="lostInput(level2item.lost_rate)"
                      ></el-input>
                      <span style="margin-left: 5px">%</span>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 11%; margin-left: 19px"
                    >
                      <span
                        style="
                          color: #4b45ff;
                          cursor: pointer;
                          padding-left: 15px;
                          white-space: nowrap;
                        "
                        @click="addLevel(level2item, 2)"
                        >添加实时弹幕</span
                      >
                      <span
                        style="color: red; cursor: pointer; padding-left: 20px"
                        @click="delLevel2(index2)"
                        >删除</span
                      >
                    </div>
                  </div>
                </div>
                <!-- 三级 -->
                <div v-if="level2item && level2item.barrage">
                  <div
                    class="item"
                    v-for="(level3item, index3) in level2item.barrage"
                    :key="`level3List_${index3}`"
                  >
                    <div
                      class="explain-body-item"
                      style="
                        width: 8%;
                        font-weight: bold;
                        padding-left: 38px;
                        box-sizing: border-box;
                        margin-right: 15px;
                      "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="width: 8%; box-sizing: "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="width: 30%; box-sizing: border-box"
                    >
                      <img
                        src="../../../assets/image/subordinate.png"
                        alt=""
                        style="
                          margin-bottom: 10px;
                          width: 20px;
                          padding-left: 5px;
                          margin-right: 25px;
                        "
                      />
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level3item.content"
                        placeholder="弹幕内容"
                      ></el-input>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 14%; box-sizing: border-box"
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level3item.appear_count"
                        @blur="conponInputFocus(level3item)"
                        placeholder="出现次数"
                      ></el-input>
                      <span style="margin-left: 5px">次</span>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 15%; box-sizing: border-box"
                    ></div>
                    <div
                      class="explain-body-item"
                      style="width: 11%; margin-right: 30px"
                    ></div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 0%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 0%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 6%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="width: 11%; margin-left: 19px"
                    >
                      <span
                        style="
                          color: #4b45ff;
                          cursor: pointer;
                          padding-left: 15px;
                        "
                        >&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span
                      >
                      <span
                        style="color: red; cursor: pointer; padding-left: 20px"
                        @click="delLevel3(index3, level3item.id)"
                        >删除</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
        <div class="foot-buttom">
          <el-button
            style="color: #4b45ff; border: 1px solid #4b45ff"
            @click="addVerbal()"
            >添加脚本话术</el-button
          >
          <el-button class="del" @click="VerbalDel()">删除</el-button>
        </div>
      </div>

      <!-- 产品卖点 -->
      <div class="explain" v-show="pointsShow" style="margin-top: 20px">
        <div class="explain_title">
          <div class="explain-header">
            <div
              class="explain-header-item"
              style="width: 7%; padding-left: 39px"
            >
              脚本类型
            </div>
            <div class="explain-header-item" style="width: 8.5%"></div>
            <div class="explain-header-item" style="width: 16%">脚本内容</div>
            <div class="explain-header-item" style="width: 14%">正确关键词</div>
            <div class="explain-header-item" style="width: 15%">错误关键词</div>
            <div class="explain-header-item" style="width: 12%">
             <span style="padding-left: 0.7vw">回答间隔时间</span>
            </div>
                <div class="explain-header-item" style="width: 10%"> <span style="padding-left: 0.7vw">转化率</span></div>
            <div class="explain-header-item" style="width: 10%"><span style="padding-left: 0.7vw">点击率</span></div>
            <div class="explain-header-item" style="width: 10%"><span style="padding-left: 0.7vw">流失率</span></div>
            <div
              class="explain-header-item"
              style="width: 15%; text-align: center"
            >
              操作
            </div>
          </div>
        </div>
        <!-- 产品卖点body -->
        <el-scrollbar ref="scropoints">
          <div class="explain-body">
            <div
              class="sum"
              v-for="(item, index) in pointsList"
              :key="`pointsList_${index}`"
            >
              <!-- 一级 -->
              <div class="item">
                <div
                  class="explain-body-item"
                  style="
                    width: 8%;
                    font-weight: bold;
                    padding-left: 38px;
                    box-sizing: border-box;
                    margin-right: 15px;
                  "
                >
                  产品卖点
                </div>
                <div
                  class="explain-body-item"
                  style="width: 7%; box-sizing: "
                ></div>
                <div
                  class="explain-body-item"
                  style="width: 15%; box-sizing: border-box"
                >
                  <el-input
                    style="width: 80%; margin-left: 5px"
                    :max="200"
                    :min="2"
                    v-model="item.script_content"
                    placeholder="请输入脚本内容"
                  ></el-input>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 12.5%; box-sizing: border-box"
                >
                  <el-input
                    style="width: 80%"
                    :max="200"
                    :min="2"
                    v-model="item.correct_keyword"
                    placeholder="请输入正确关键词"
                  ></el-input>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 12.5%; box-sizing: border-box"
                >
                  <el-input
                    style="width: 80%"
                    :max="200"
                    :min="2"
                    v-model="item.wrong_keyword"
                    placeholder="请输入错误关键词"
                  ></el-input>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 10%; margin-right: 30px"
                >
                  <el-input-number
                    :controls="false"
                    :precision="0"
                    style="width: 80px; margin-left: 5px"
                    :max="60"
                    :min="0"
                    v-model="item.time"
                  ></el-input-number>
                  <span style="margin-left: 5px">分</span>
                  <el-input-number
                    :controls="false"
                    :precision="0"
                    style="width: 80px; margin-left: 5px"
                    :max="60"
                    :min="0"
                    v-model="item.time1"
                  ></el-input-number>
                  <span style="margin-left: 5px">秒</span>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 9%; box-sizing: border-box; padding-right: 20px"
                >
                  <el-input
                    style="width: 80%"
                    :max="200"
                    :min="2"
                    v-model="item.change_rate"
                    placeholder="0.01-10"
                    @blur="changeInput(item.change_rate)"
                  ></el-input>
                  <span style="margin-left: 5px">%</span>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 9%; box-sizing: border-box; padding-right: 20px"
                >
                  <el-input
                    style="width: 80%"
                    :max="200"
                    :min="2"
                    v-model="item.click_rate"
                    placeholder="0.01-10"
                    @blur="clickInput(item.click_rate)"
                  ></el-input>
                  <span style="margin-left: 5px">%</span>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 9%; box-sizing: border-box; padding-right: 20px"
                >
                  <el-input
                    style="width: 80%"
                    :max="200"
                    :min="2"
                    v-model="item.lost_rate"
                    placeholder="0.01-10"
                    @blur="lostInput(item.lost_rate)"
                  ></el-input>
                  <span style="margin-left: 5px">%</span>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 11%; margin-left: 19px"
                >
                  <span
                    style="color: #4b45ff; cursor: pointer; padding-left: 15px"
                    >&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span
                  >
                  <span
                    style="color: red; cursor: pointer; padding-left: 20px"
                    @click="delpoints1(index)"
                    >删除</span
                  >
                </div>
              </div>
              <!-- 二级级三级外层 -->
              <div
                v-for="(level2item, index2) in item.children"
                :key="`level2List_${index2}`"
              >
                <!-- 二级 -->
                <div v-if="item && item.children">
                  <div class="item">
                    <div
                      class="explain-body-item"
                      style="
                        width: 8%;
                        font-weight: bold;
                        padding-left: 38px;
                        box-sizing: border-box;
                        margin-right: 15px;
                      "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="width: 8%; box-sizing: "
                    >
                      <img
                        src="../../../assets/image/subordinate.png"
                        alt=""
                        style="
                          margin-bottom: 10px;
                          width: 20px;
                          padding-left: 40px;
                        "
                      />
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 15%; box-sizing: border-box"
                    >
                      <el-input
                        style="width: 80%; margin-left: 5px"
                        :max="200"
                        :min="2"
                        v-model="level2item.script_content"
                        placeholder="请输入脚本内容"
                      ></el-input>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 12.5%; box-sizing: border-box"
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level2item.correct_keyword"
                        placeholder="请输入正确关键词"
                      ></el-input>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 12.5%; box-sizing: border-box"
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level2item.wrong_keyword"
                        placeholder="请输入错误关键词"
                      ></el-input>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 10%; margin-right: 30px"
                    >
                      <el-input-number
                        :controls="false"
                        :precision="0"
                        style="width: 80px; margin-left: 5px"
                        :max="60"
                        :min="0"
                        v-model="level2item.time"
                      ></el-input-number>
                      <span style="margin-left: 5px">分</span>
                      <el-input-number
                        :controls="false"
                        :precision="0"
                        style="width: 80px; margin-left: 5px"
                        :max="60"
                        :min="0"
                        v-model="level2item.time1"
                      ></el-input-number>
                      <span style="margin-left: 5px">秒</span>
                    </div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 9%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level2item.change_rate"
                        placeholder="0.01-10"
                        @blur="changeInput(level2item.change_rate)"
                      ></el-input>
                      <span style="margin-left: 5px">%</span>
                    </div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 9%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level2item.click_rate"
                        placeholder="0.01-10"
                        @blur="clickInput(level2item.click_rate)"
                      ></el-input>
                      <span style="margin-left: 5px">%</span>
                    </div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 9%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level2item.lost_rate"
                        placeholder="0.01-10"
                        @blur="lostInput(level2item.lost_rate)"
                      ></el-input>
                      <span style="margin-left: 5px">%</span>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 11%; margin-left: 19px"
                    >
                      <span
                        style="
                          color: #4b45ff;
                          cursor: pointer;
                          padding-left: 15px;
                          white-space: nowrap;
                        "
                        @click="addpoints(level2item, 2)"
                        >添加实时弹幕</span
                      >
                      <span
                        style="color: red; cursor: pointer; padding-left: 20px"
                        @click="delpoints2(index2)"
                        >删除</span
                      >
                    </div>
                  </div>
                </div>
                <!-- 三级 -->
                <div v-if="level2item && level2item.barrage">
                  <div
                    class="item"
                    v-for="(level3item, index3) in level2item.barrage"
                    :key="`level3List_${index3}`"
                  >
                    <div
                      class="explain-body-item"
                      style="
                        width: 8%;
                        font-weight: bold;
                        padding-left: 38px;
                        box-sizing: border-box;
                        margin-right: 15px;
                      "
                    >
                      {{ level3item.type }}
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 8%; box-sizing: "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="width: 30%; box-sizing: border-box"
                    >
                      <img
                        src="../../../assets/image/subordinate.png"
                        alt=""
                        style="
                          margin-bottom: 10px;
                          width: 20px;
                          padding-left: 5px;
                          margin-right: 25px;
                        "
                      />
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level3item.content"
                        placeholder="弹幕内容"
                      ></el-input>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 14%; box-sizing: border-box"
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level3item.appear_count"
                        @blur="conponInputFocus(level3item)"
                        placeholder="出现次数"
                      ></el-input>
                      <span style="margin-left: 5px">次</span>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 15%; box-sizing: border-box"
                    ></div>
                    <div
                      class="explain-body-item"
                      style="width: 11%; margin-right: 30px"
                    ></div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 0%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 0%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 6%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="width: 11%; margin-left: 19px"
                    >
                      <span
                        style="
                          color: #4b45ff;
                          cursor: pointer;
                          padding-left: 15px;
                        "
                        >&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span
                      >
                      <span
                        style="color: red; cursor: pointer; padding-left: 20px"
                        @click="delpoints3(index3, level3item.id)"
                        >删除</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
        <div class="foot-buttom">
          <el-button
            style="color: #4b45ff; border: 1px solid #4b45ff"
            @click="addpointsButton()"
            >添加脚本话术</el-button
          >
          <el-button class="del" @click="pointsDel()">删除</el-button>
        </div>
      </div>

      <!-- 优惠券 -->
      <div class="explain" v-show="discountsShow" style="margin-top: 20px">
        <div class="explain_title">
          <div class="explain-header">
            <div
              class="explain-header-item"
              style="width: 7%; padding-left: 39px"
            >
              脚本类型
            </div>
            <div class="explain-header-item" style="width: 8.5%">动作</div>
            <div class="explain-header-item" style="width: 16%">脚本内容</div>
            <div class="explain-header-item" style="width: 14%">正确关键词</div>
            <div class="explain-header-item" style="width: 15%">错误关键词</div>
            <div class="explain-header-item" style="width: 12%">
              <span style="padding-left: 0.7vw">回答间隔时间</span>
            </div>
           <div class="explain-header-item" style="width: 10%"> <span style="padding-left: 0.7vw">转化率</span></div>
            <div class="explain-header-item" style="width: 10%"><span style="padding-left: 0.7vw">点击率</span></div>
            <div class="explain-header-item" style="width: 10%"><span style="padding-left: 0.7vw">流失率</span></div>
            <div
              class="explain-header-item"
              style="width: 15%; text-align: center"
            >
              操作
            </div>
          </div>
        </div>
        <!-- 优惠券body -->
        <el-scrollbar ref="scrodiscounts">
          <div class="explain-body">
            <div
              class="sum"
              v-for="(item, index) in discountsList"
              :key="`explainList_${index}`"
            >
              <!-- 一级 -->
              <div class="item">
                <div
                  class="explain-body-item"
                  style="
                    width: 8%;
                    font-weight: bold;
                    padding-left: 38px;
                    box-sizing: border-box;
                    margin-right: 15px;
                  "
                >
                  优惠券
                </div>
                <div class="explain-body-item" style="width: 7%; box-sizing: ">
                  有/无发布
                </div>
                <div
                  class="explain-body-item"
                  style="width: 15%; box-sizing: border-box"
                >
                  <el-input
                    style="width: 80%; margin-left: 5px"
                    :max="200"
                    :min="2"
                    v-model="item.script_content"
                    placeholder="请输入脚本内容"
                  ></el-input>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 12.5%; box-sizing: border-box"
                >
                  <el-input
                    style="width: 80%"
                    :max="200"
                    :min="2"
                    v-model="item.correct_keyword"
                    :disabled="disabled"
                  ></el-input>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 12.5%; box-sizing: border-box"
                >
                  <el-input
                    style="width: 80%"
                    :max="200"
                    :min="2"
                    v-model="item.wrong_keyword"
                    :disabled="disabled"
                  ></el-input>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 10%; margin-right: 30px"
                >
                  <el-input-number
                    :controls="false"
                    :precision="0"
                    style="width: 80px; margin-left: 5px"
                    :max="60"
                    :min="0"
                    v-model="item.time"
                  ></el-input-number>
                  <span style="margin-left: 5px">分</span>
                  <el-input-number
                    :controls="false"
                    :precision="0"
                    style="width: 80px; margin-left: 5px"
                    :max="60"
                    :min="0"
                    v-model="item.time1"
                  ></el-input-number>
                  <span style="margin-left: 5px">秒</span>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 9%; box-sizing: border-box; padding-right: 20px"
                >
                  <el-input
                    style="width: 80%"
                    :max="200"
                    :min="2"
                    v-model="item.change_rate"
                    placeholder="0.01-10"
                    @blur="changeInput(item.change_rate)"
                  ></el-input>
                  <span style="margin-left: 5px">%</span>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 9%; box-sizing: border-box; padding-right: 20px"
                >
                  <el-input
                    style="width: 80%"
                    :max="200"
                    :min="2"
                    v-model="item.click_rate"
                    placeholder="0.01-10"
                    @blur="clickInput(item.click_rate)"
                  ></el-input>
                  <span style="margin-left: 5px">%</span>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 9%; box-sizing: border-box; padding-right: 20px"
                >
                  <el-input
                    style="width: 80%"
                    :max="200"
                    :min="2"
                    v-model="item.lost_rate"
                    placeholder="0.01-10"
                    @blur="lostInput(item.lost_rate)"
                  ></el-input>
                  <span style="margin-left: 5px">%</span>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 11%; margin-left: 19px"
                >
                  <span
                    style="color: #4b45ff; cursor: pointer; padding-left: 15px"
                    >&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span
                  >
                  <span
                    style="color: red; cursor: pointer; padding-left: 20px"
                    @click="delDiscounts1(index)"
                    >删除</span
                  >
                </div>
              </div>
              <!-- 二级级三级外层 -->
              <div
                v-for="(level2item, index2) in item.children"
                :key="`level2List_${index2}`"
              >
                <!-- 二级 -->
                <div v-if="item && item.children">
                  <div class="item">
                    <div
                      class="explain-body-item"
                      style="
                        width: 8%;
                        font-weight: bold;
                        padding-left: 38px;
                        box-sizing: border-box;
                        margin-right: 15px;
                      "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="width: 8%; box-sizing: "
                    >
                      <img
                        src="../../../assets/image/subordinate.png"
                        alt=""
                        style="
                          margin-bottom: 10px;
                          width: 20px;
                          padding-left: 40px;
                        "
                      />
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 15%; box-sizing: border-box"
                    >
                      <el-input
                        style="width: 80%; margin-left: 5px"
                        :max="200"
                        :min="2"
                        v-model="level2item.script_content"
                        placeholder="请输入脚本内容"
                      ></el-input>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 12.5%; box-sizing: border-box"
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level2item.correct_keyword"
                        placeholder="请输入正确关键词"
                      ></el-input>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 12.5%; box-sizing: border-box"
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level2item.wrong_keyword"
                        placeholder="请输入错误关键词"
                      ></el-input>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 10%; margin-right: 30px"
                    >
                      <el-input-number
                        :controls="false"
                        :precision="0"
                        style="width: 80px; margin-left: 5px"
                        :max="60"
                        :min="0"
                        v-model="level2item.time"
                      ></el-input-number>
                      <span style="margin-left: 5px">分</span>
                      <el-input-number
                        :controls="false"
                        :precision="0"
                        style="width: 80px; margin-left: 5px"
                        :max="60"
                        :min="0"
                        v-model="level2item.time1"
                      ></el-input-number>
                      <span style="margin-left: 5px">秒</span>
                    </div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 9%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level2item.change_rate"
                        placeholder="0.01-10"
                        @blur="changeInput(level2item.change_rate)"
                      ></el-input>
                      <span style="margin-left: 5px">%</span>
                    </div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 9%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level2item.click_rate"
                        placeholder="0.01-10"
                        @blur="clickInput(level2item.click_rate)"
                      ></el-input>
                      <span style="margin-left: 5px">%</span>
                    </div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 9%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level2item.lost_rate"
                        placeholder="0.01-10"
                        @blur="lostInput(level2item.lost_rate)"
                      ></el-input>
                      <span style="margin-left: 5px">%</span>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 11%; margin-left: 19px"
                    >
                      <span
                        style="
                          color: #4b45ff;
                          cursor: pointer;
                          padding-left: 15px;
                        "
                        @click="addDiscounts(level2item, 2)"
                        >添加实时弹幕</span
                      >
                      <span
                        style="color: red; cursor: pointer; padding-left: 20px"
                        @click="delDiscounts2(index2)"
                        >删除</span
                      >
                    </div>
                  </div>
                </div>
                <!-- 三级 -->
                <div v-if="level2item && level2item.barrage">
                  <div
                    class="item"
                    v-for="(level3item, index3) in level2item.barrage"
                    :key="`level3List_${index3}`"
                  >
                    <div
                      class="explain-body-item"
                      style="
                        width: 8%;
                        font-weight: bold;
                        padding-left: 38px;
                        box-sizing: border-box;
                        margin-right: 15px;
                      "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="width: 8%; box-sizing: "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="width: 30%; box-sizing: border-box"
                    >
                      <img
                        src="../../../assets/image/subordinate.png"
                        alt=""
                        style="
                          margin-bottom: 10px;
                          width: 20px;
                          padding-left: 5px;
                          margin-right: 25px;
                        "
                      />
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level3item.content"
                        placeholder="弹幕内容"
                      ></el-input>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 14%; box-sizing: border-box"
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level3item.appear_count"
                        placeholder="出现次数"
                        @blur="conponInputFocus(level3item)"
                      ></el-input>
                      <span style="margin-left: 5px">次</span>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 15%; box-sizing: border-box"
                    ></div>
                    <div
                      class="explain-body-item"
                      style="width: 11%; margin-right: 30px"
                    ></div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 0%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 0%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 6%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="width: 11%; margin-left: 19px"
                    >
                      <span
                        style="
                          color: #4b45ff;
                          cursor: pointer;
                          padding-left: 15px;
                        "
                        >&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span
                      >
                      <span
                        style="color: red; cursor: pointer; padding-left: 20px"
                        @click="delDiscounts3(index3, level3item.id)"
                        >删除</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
        <div class="foot-buttom">
          <el-button
            style="color: #4b45ff; border: 1px solid #4b45ff"
            @click="addDiscountsButtom()"
            >添加脚本话术</el-button
          >
          <el-button class="del" @click="DiscountsDel()">删除</el-button>
        </div>
      </div>

      <!-- 优惠券类型 -->
      <div class="explain" v-show="discountsShow" style="margin-top: 20px">
        <div class="explain_title">
          <div class="explain-header">
            <div
              class="explain-header-item"
              style="width: 30%; padding-left: 39px"
            >
              优惠券类型
            </div>
            <!-- <div class="explain-header-item" style="width: 20%">优惠券名称</div> -->
            <div class="explain-header-item" style="width: 20%">优惠券内容</div>
            <div class="explain-header-item" style="width: 20%">发放量</div>
            <div class="explain-header-item" style="width: 10%">限领</div>
          </div>
        </div>
        <!-- 优惠券body -->
        <el-scrollbar ref="scrodiscounts">
          <div class="explain-body">
            <div
              class="sum"
              v-for="(item, index) in discount"
              :key="`typeList${index}`"
            >
              <div class="item">
                <div
                  class="explain-body-item"
                  style="
                    width: 30%;
                    font-weight: bold;
                    padding-left: 38px;
                    box-sizing: border-box;
                    margin-right: 42px;
                  "
                >
                  <span v-if="item.coupon_type == 1">指定商品满减卷</span>
                  <span v-if="item.coupon_type == 2">指定商品折扣卷</span>
                </div>

                <!-- <div
                  class="explain-body-item"
                  style="width: 20%; box-sizing: border-box"
                >
                  {{ item.coupon_name }}
                </div> -->
                <div
                  class="explain-body-item"
                  style="width: 20%; box-sizing: border-box"
                >
                  <div v-if="item.coupon_type == 1">
                    满{{ item.coupon_full }}减{{ item.coupon_minus }}元
                  </div>
                  <div v-if="item.coupon_type == 2">
                    满{{ item.coupon_full }}打{{ item.coupon_minus }}折
                  </div>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 20%; box-sizing: border-box"
                >
                  {{ item.grant_count }}张
                </div>
                <div
                  class="explain-body-item"
                  style="
                    width: 10%;
                    box-sizing: border-box;
                    padding-right: 20px;
                  "
                >
                  {{ item.everyone_count }}张
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>

      <!-- 商品问答 -->
      <div class="explain" v-show="goodsIssueShow" style="margin-top: 20px">
        <div class="explain_title">
          <div class="explain-header">
            <div
              class="explain-header-item"
              style="width: 7%; padding-left: 39px"
            >
              脚本类型
            </div>
            <div class="explain-header-item" style="width: 8.5%"></div>
            <div class="explain-header-item" style="width: 16%">脚本内容</div>
            <div class="explain-header-item" style="width: 14%">关键词</div>
            <div class="explain-header-item" style="width: 15%">错误关键词</div>
            <div class="explain-header-item" style="width: 12%">
              <span style="padding-left: 2vw">回答间隔时间</span>
            </div>
            <div class="explain-header-item" style="width: 10%"> <span style="padding-left: 0.7vw">转化率</span></div>
            <div class="explain-header-item" style="width: 10%"><span style="padding-left: 0.7vw">点击率</span></div>
            <div class="explain-header-item" style="width: 10%"><span style="padding-left: 0.7vw">流失率</span></div>
            <div
              class="explain-header-item"
              style="width: 15%; text-align: center"
            >
              操作
            </div>
          </div>
        </div>
        <!-- 商品问答body -->
        <el-scrollbar ref="scrogoodsIssue">
          <div class="explain-body">
            <div
              class="sum"
              v-for="(item, index) in goodsIssueList"
              :key="`explainList_${index}`"
            >
              <!-- 一级 -->
              <div class="item">
                <div
                  class="explain-body-item"
                  style="
                    width: 8%;
                    font-weight: bold;
                    padding-left: 38px;
                    box-sizing: border-box;
                    margin-right: 15px;
                  "
                >
                  商品问答
                </div>
                <div
                  class="explain-body-item"
                  style="width: 7%; box-sizing: "
                ></div>
                <div
                  class="explain-body-item"
                  style="width: 15%; box-sizing: border-box"
                >
                  <el-input
                    style="width: 80%; margin-left: 5px"
                    :max="200"
                    :min="2"
                    v-model="item.script_content"
                    placeholder="请输入脚本内容"
                  ></el-input>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 12.5%; box-sizing: border-box"
                >
                  <el-input
                    style="width: 80%"
                    :max="200"
                    :min="2"
                    v-model="item.correct_keyword"
                    placeholder="请输入正确关键词2-150"
                  ></el-input>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 12.5%; box-sizing: border-box"
                >
                  <el-input
                    style="width: 80%"
                    :max="200"
                    :min="2"
                    v-model="item.wrong_keyword"
                    placeholder="请输入错误关键词2-150"
                  ></el-input>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 10%; margin-right: 30px"
                >
                  <el-input-number
                    :controls="false"
                    :precision="0"
                    style="width: 80px; margin-left: 5px"
                    :max="60"
                    :min="0"
                    v-model="item.time"
                  ></el-input-number>
                  <span style="margin-left: 5px">分</span>
                  <el-input-number
                    :controls="false"
                    :precision="0"
                    style="width: 80px; margin-left: 5px"
                    :max="60"
                    :min="0"
                    v-model="item.time1"
                  ></el-input-number>
                  <span style="margin-left: 5px">秒</span>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 9%; box-sizing: border-box; padding-right: 20px"
                >
                  <el-input
                    style="width: 80%"
                    :max="200"
                    :min="2"
                    v-model="item.change_rate"
                    placeholder="0.01-10"
                    @blur="changeInput(item.change_rate)"
                  ></el-input>
                  <span style="margin-left: 5px">%</span>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 9%; box-sizing: border-box; padding-right: 20px"
                >
                  <el-input
                    style="width: 80%"
                    :max="200"
                    :min="2"
                    v-model="item.click_rate"
                    placeholder="0.01-10"
                    @blur="clickInput(item.click_rate)"
                  ></el-input>
                  <span style="margin-left: 5px">%</span>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 9%; box-sizing: border-box; padding-right: 20px"
                >
                  <el-input
                    style="width: 80%"
                    :max="200"
                    :min="2"
                    v-model="item.lost_rate"
                    placeholder="0.01-10"
                    @blur="lostInput(item.lost_rate)"
                  ></el-input>
                  <span style="margin-left: 5px">%</span>
                </div>
                <div
                  class="explain-body-item"
                  style="width: 11%; margin-left: 19px"
                >
                  <span
                    style="color: #4b45ff; cursor: pointer; padding-left: 15px"
                    >&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span
                  >
                  <span
                    style="color: red; cursor: pointer; padding-left: 20px"
                    @click="delGoodsIssue1(index)"
                    >删除</span
                  >
                </div>
              </div>
              <!-- 二级级三级外层 -->
              <div
                v-for="(level2item, index2) in item.children"
                :key="`level2List_${index2}`"
              >
                <!-- 二级 -->
                <div v-if="item && item.children">
                  <div class="item">
                    <div
                      class="explain-body-item"
                      style="
                        width: 8%;
                        font-weight: bold;
                        padding-left: 38px;
                        box-sizing: border-box;
                        margin-right: 15px;
                      "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="width: 8%; box-sizing: "
                    >
                      <img
                        src="../../../assets/image/subordinate.png"
                        alt=""
                        style="
                          margin-bottom: 10px;
                          width: 20px;
                          padding-left: 40px;
                        "
                      />
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 15%; box-sizing: border-box"
                    >
                      <el-input
                        style="width: 80%; margin-left: 5px"
                        :max="200"
                        :min="2"
                        v-model="level2item.script_content"
                        placeholder="请输入脚本内容"
                      ></el-input>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 12.5%; box-sizing: border-box"
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level2item.correct_keyword"
                        placeholder="请输入正确关键词"
                      ></el-input>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 12.5%; box-sizing: border-box"
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level2item.wrong_keyword"
                        placeholder="请输入错误关键词"
                      ></el-input>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 10%; margin-right: 30px"
                    >
                      <el-input-number
                        :controls="false"
                        :precision="0"
                        style="width: 80px; margin-left: 5px"
                        :max="60"
                        :min="0"
                        v-model="level2item.time"
                      ></el-input-number>
                      <span style="margin-left: 5px">分</span>
                      <el-input-number
                        :controls="false"
                        :precision="0"
                        style="width: 80px; margin-left: 5px"
                        :max="60"
                        :min="0"
                        v-model="level2item.time1"
                      ></el-input-number>
                      <span style="margin-left: 5px">秒</span>
                    </div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 9%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level2item.change_rate"
                        placeholder="0.01-10"
                      ></el-input>
                      <span style="margin-left: 5px">%</span>
                    </div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 9%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level2item.click_rate"
                        placeholder="0.01-10"
                      ></el-input>
                      <span style="margin-left: 5px">%</span>
                    </div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 9%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level2item.lost_rate"
                        placeholder="0.01-10"
                      ></el-input>
                      <span style="margin-left: 5px">%</span>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 11%; margin-left: 19px"
                    >
                      <span
                        style="
                          color: #4b45ff;
                          cursor: pointer;
                          padding-left: 15px;
                        "
                        @click="addgoodsIssue(level2item, 2)"
                        >添加实时弹幕</span
                      >
                      <span
                        style="color: red; cursor: pointer; padding-left: 20px"
                        @click="delGoodsIssue2(index2)"
                        >删除</span
                      >
                    </div>
                  </div>
                </div>
                <!-- 三级 -->
                <div v-if="level2item && level2item.barrage">
                  <div
                    class="item"
                    v-for="(level3item, index3) in level2item.barrage"
                    :key="`level3List_${index3}`"
                  >
                    <div
                      class="explain-body-item"
                      style="
                        width: 8%;
                        font-weight: bold;
                        padding-left: 38px;
                        box-sizing: border-box;
                        margin-right: 15px;
                      "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="width: 8%; box-sizing: "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="width: 30%; box-sizing: border-box"
                    >
                      <img
                        src="../../../assets/image/subordinate.png"
                        alt=""
                        style="
                          margin-bottom: 10px;
                          width: 20px;
                          padding-left: 5px;
                          margin-right: 25px;
                        "
                      />
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level3item.content"
                        placeholder="弹幕内容"
                      ></el-input>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 14%; box-sizing: border-box"
                    >
                      <el-input
                        style="width: 80%"
                        :max="200"
                        :min="2"
                        v-model="level3item.appear_count"
                        placeholder="出现次数"
                        @blur="conponInputFocus(level3item)"
                      ></el-input>
                      <span style="margin-left: 5px">次</span>
                    </div>
                    <div
                      class="explain-body-item"
                      style="width: 15%; box-sizing: border-box"
                    ></div>
                    <div
                      class="explain-body-item"
                      style="width: 11%; margin-right: 30px"
                    ></div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 0%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 0%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="
                        width: 6%;
                        box-sizing: border-box;
                        padding-right: 20px;
                      "
                    ></div>
                    <div
                      class="explain-body-item"
                      style="width: 11%; margin-left: 19px"
                    >
                      <span
                        style="
                          color: #4b45ff;
                          cursor: pointer;
                          padding-left: 15px;
                        "
                        >&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span
                      >
                      <span
                        style="color: red; cursor: pointer; padding-left: 20px"
                        @click="delGoodsIssue3(index3, level3item.id)"
                        >删除</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
        <div class="foot-buttom">
          <el-button
            style="color: #4b45ff; border: 1px solid #4b45ff"
            @click="addgoodsIssueButtom()"
            >添加脚本话术</el-button
          >
          <el-button class="del" @click="goodsIssueDel()">删除</el-button>
        </div>
      </div>

      <!-- 问题 -->
      <div class="explain" v-show="goodsIssueShow" style="margin-top: 20px">
        <div class="explain_title">
          <div class="explain-header">
            <div
              class="explain-header-item"
              style="width: 30%; padding-left: 39px"
            >
              问题
            </div>
            <div class="explain-header-item" style="width: 20%">正确关键词</div>
            <div class="explain-header-item" style="width: 20%">错误关键词</div>
            <div class="explain-header-item" style="width: 20%">
              问题间隔时间
            </div>
          </div>
        </div>
        <!-- 问题body -->
        <el-scrollbar ref="scrodiscounts">
          <div class="explain-body">
            <div
              class="sum"
              v-for="(item, index) in issueList"
              :key="`typeList${index}`"
            >
              <div class="item">
                <div
                  class="explain-body-item"
                  style="
                    width: 30%;
                    font-weight: bold;
                    padding-left: 38px;
                    box-sizing: border-box;
                    margin-right: 42px;
                  "
                >
                  {{ item.question_name }}
                </div>

                <div
                  class="explain-body-item"
                  style="width: 20%; box-sizing: border-box"
                >
                  {{ item.correct_keyword }}
                </div>
                <div
                  class="explain-body-item"
                  style="width: 20%; box-sizing: border-box"
                >
                  {{ item.wrong_keyword }}
                </div>

                <div
                  class="explain-body-item"
                  style="width: 10%; margin-right: 30px"
                >
                  <el-input-number
                    :controls="false"
                    :precision="0"
                    style="width: 80px; margin-left: 5px"
                    maxlength="2"
                    :min="0"
                    v-model="item.issuetime"
                  ></el-input-number>
                  <span style="margin-left: 5px">分</span>
                  <el-input-number
                    :controls="false"
                    :precision="0"
                    style="width: 80px; margin-left: 5px"
                    maxlength="2"
                    :min="0"
                    v-model="item.issuetime1"
                  ></el-input-number>
                  <span style="margin-left: 5px">秒</span>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </el-scrollbar>
    <div class="footer-button">
      <el-button type="primary" @click="backTo">上一步</el-button>
      <el-button type="primary" @click="next">下一步</el-button>
    </div>
    <el-dialog
      :visible.sync="dialogAddClassify"
      custom-class="green-dialog"
      width="500px"
      @close="resetForm()"
      :close-on-click-modal="false"
    >
      <template slot="title">
        <div style="font-weight: 600">
          <span
            style="
              display: inline-block;
              width: 4px;
              height: 12px;
              margin-right: 10px;
              background-color: #2821fc;
            "
          ></span
          >{{ this.classifyTitleType }}
        </div>
      </template>
      <el-form
        :model="addForm"
        ref="addForm"
        :rules="resourcesRules"
        label-width="100px"
        class="demo-form-inline"
      >
        <el-form-item label="脚本类型" prop="name">
          <el-select v-model="addForm.name" clearable placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button size="medium" @click="dialogAddClassify = false"
          >取 消</el-button
        >
        <el-button size="medium" type="primary" @click="addToForm('addForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>


<script>
import { save } from "@/utils/apis";
export default {
  data() {
    return {
      img: 0,
      disabled: true,
      cate_id: 0,

      resourcesRules: {
        name: [
          { required: true, message: "请选择行业分类", trigger: "change" },
        ],
      },
      //商品讲解显示
      explainShow: false,
      //产品卖点介绍显示
      pointsShow: false,
      //优惠券显示
      discountsShow: false,
      //商品问题显示
      goodsIssueShow: false,

      //商品讲解
      explainList: [],
      //产品卖点介绍
      pointsList: [],
      //优惠券
      discountsList: [],
      //优惠券类型
      //商品问题
      goodsIssueList: [],
      dialogAddClassify: false,
      classifyTitleType: "",
      //添加脚本类型
      addForm: {
        name: "",
      },
      options: [
        {
          label: "商品讲解",
          value: 1,
        },
        {
          label: "产品卖点介绍",
          value: 2,
        },
        {
          label: "优惠券",
          value: 3,
        },
        {
          label: "商品问答",
          value: 4,
        },
      ],

      //问题
      problemList: [
        {
          time: 0, //分
          time1: 0, //秒
        },
      ],
      price: "", //获取前面页面标题数据
      discount: [], //获取优惠券数据
      issueList: [], //获取问题数据
      goods_script: [],
      goods_scriptArr: [],
      sum_id: "",
    };
  },

  created() {
    //获取商品标题数据
    this.price = JSON.parse(localStorage.getItem("message"));
    this.$bus.$on("title", () => {
      this.price = JSON.parse(localStorage.getItem("message"));
    });
    //获取优惠券数据
    this.discount = JSON.parse(localStorage.getItem("conponDate"));
    this.$bus.$on("conpons", () => {
      this.discount = JSON.parse(localStorage.getItem("conponDate"));
    });
    //获取问题数据
    this.issueList = JSON.parse(localStorage.getItem("answers"));

    this.$bus.$on("answersList", () => {
       this.issueList = JSON.parse(localStorage.getItem("answers"));
        this.editEstablish();
    });
    this.editEstablish();

  
  },
  methods: {
    conponInputFocus(val) {
      if (val.appear_count == "0") {
        this.$message.error("请设置大于0的数");
      }
    },

    //转化率验证
    changeInput(val) {
      let two =
        /^[-]?([1-9]{1}[0-9]{0,}(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|\.[0-9]{1,2})$/g;
      if (!two.test(val)) {
        this.$message.error("转化率最多保留两位小数");
      } else if (isNaN(val) || val > 10) {
        this.$message.error("转化率范围在0.01-10之间");
      } else if (isNaN(val) || val == 0) {
        this.$message.error("转化率必须大于0");
      }
    },
    //点击率验证
    clickInput(val) {
      let two =
        /^[-]?([1-9]{1}[0-9]{0,}(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|\.[0-9]{1,2})$/g;
      if (!two.test(val)) {
        this.$message.error("点击率最多保留两位小数");
      } else if (isNaN(val) || val > 10) {
        this.$message.error("点击率范围在0.01-10之间");
      } else if (isNaN(val) || val == 0) {
        this.$message.error("点击率必须大于0");
      }
    },
    //流失率验证
    lostInput(val) {
      let two =
        /^[-]?([1-9]{1}[0-9]{0,}(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|\.[0-9]{1,2})$/g;
      if (!two.test(val)) {
        this.$message.error("流失率率最多保留两位小数");
      } else if (isNaN(val) || val > 10) {
        this.$message.error("流失率率范围在0.01-10之间");
      } else if (isNaN(val) || val == 0) {
        this.$message.error("流失率率必须大于0");
      }
    },

    editEstablish() {
      if (this.$route.query.id) {
        let eiitList = JSON.parse(localStorage.getItem("commodityeditData"));
        this.sum_id = this.$route.query.id;

        //编辑商品讲解
        let edites_arr = [];
        //编辑产品卖点介绍
        let editpo_arr = [];
        //编辑优惠券
        let editdi_arr = [];
        //编辑产品问题
        let editgo_arr = [];
        //编辑分四个数组
        for (let i = 0; i < eiitList.goods_script.length; i++) {
          if (eiitList.goods_script[i].script_type == 1) {
            this.img = 1;
            this.explainShow = true;
            edites_arr = eiitList.goods_script[i];
          } else if (eiitList.goods_script[i].script_type == 4) {
            this.img = 1;
            this.pointsShow = true;
            editpo_arr = eiitList.goods_script[i];
          } else if (eiitList.goods_script[i].script_type == 2) {
            this.img = 1;
            this.discountsShow = true;
            editdi_arr = eiitList.goods_script[i];
          } else if (eiitList.goods_script[i].script_type == 3) {
            this.img = 1;
            this.goodsIssueShow = true;
            editgo_arr = eiitList.goods_script[i];
            for (let i = 0; i < this.issueList.length; i++) {
              this.issueList[i].issuetime = Math.floor(
                this.issueList[i].answer_time / 60
              );
              this.issueList[i].issuetime1 = Math.floor(
                this.issueList[i].answer_time % 60
              );
            }
           
          }
        }

        if (edites_arr != "") {
          this.explainList =[]
          this.explainList.push({
            script_type: edites_arr.script_type,
            action: edites_arr.action,
            script_content: edites_arr.script_content, //脚本内容
            correct_keyword: edites_arr.correct_keyword, //正确关键词
            wrong_keyword: edites_arr.wrong_keyword, //错误关键词
            time: Math.floor(edites_arr.answer_time / 60), //分
            time1: Math.floor(edites_arr.answer_time % 60), //秒
            answer_time: edites_arr.answer_time, //传给后台时间总和
            change_rate: edites_arr.change_rate, //转化率
            click_rate: edites_arr.click_rate, //点击率
            lost_rate: edites_arr.lost_rate, //流失率
            children: [],
          });
          if (edites_arr.children) {        
            for (let i = 0; i < edites_arr.children.length; i++) {
              this.explainList[0].children.push({
                action: edites_arr.children[i].action,
                script_content: edites_arr.children[i].script_content, //脚本内容
                correct_keyword: edites_arr.children[i].correct_keyword, //正确关键词
                wrong_keyword: edites_arr.children[i].wrong_keyword, //错误关键词
                time: Math.floor(edites_arr.children[i].answer_time / 60), //分
                time1: Math.floor(edites_arr.children[i].answer_time % 60), //秒
                answer_time: edites_arr.children[i].answer_time, //传给后台时间总和
                change_rate: edites_arr.children[i].change_rate, //转化率
                click_rate: edites_arr.children[i].click_rate, //点击率
                lost_rate: edites_arr.children[i].lost_rate, //流失率
                barrage: [],
              });
              if (edites_arr.children[i].barrage ) {
                for (let j = 0;j < edites_arr.children[i].barrage.length;j++ ) {
                  this.explainList[0].children[i].barrage.push({
                    id: edites_arr.children[i].barrage[j].id,
                    content: edites_arr.children[i].barrage[j].content,
                    appear_count:
                      edites_arr.children[i].barrage[j].appear_count,
                  });
                }
              }
            }
          }
        }
        if (editpo_arr != "") {
          this.pointsList=[]
          this.pointsList.push({
            script_type: editpo_arr.script_type,
            action: editpo_arr.action,
            script_content: editpo_arr.script_content, //脚本内容
            correct_keyword: editpo_arr.correct_keyword, //正确关键词
            wrong_keyword: editpo_arr.wrong_keyword, //错误关键词
            time: Math.floor(editpo_arr.answer_time / 60), //分
            time1: Math.floor(editpo_arr.answer_time % 60), //秒
            answer_time: editpo_arr.answer_time, //传给后台时间总和
            change_rate: editpo_arr.change_rate, //转化率
            click_rate: editpo_arr.click_rate, //点击率
            lost_rate: editpo_arr.lost_rate, //流失率
            children: [],
          });
          if (editpo_arr.children) {
            for (let i = 0; i < editpo_arr.children.length; i++) {
              this.pointsList[0].children.push({
                id: this.cate_id++,
                action: editpo_arr.children[i].action,
                script_content: editpo_arr.children[i].script_content, //脚本内容
                correct_keyword: editpo_arr.children[i].correct_keyword, //正确关键词
                wrong_keyword: editpo_arr.children[i].wrong_keyword, //错误关键词
                time: Math.floor(editpo_arr.children[i].answer_time / 60), //分
                time1: Math.floor(editpo_arr.children[i].answer_time % 60), //秒
                answer_time: editpo_arr.children[i].answer_time, //传给后台时间总和
                change_rate: editpo_arr.children[i].change_rate, //转化率
                click_rate: editpo_arr.children[i].click_rate, //点击率
                lost_rate: editpo_arr.children[i].lost_rate, //流失率
                barrage: [],
              });
              if (editpo_arr.children[i].barrage) {
                for (let j = 0; j < editpo_arr.children[i].barrage.length;j++) {
                  this.pointsList[0].children[i].barrage.push({
                    id: editpo_arr.children[i].barrage[j].id,
                    content: editpo_arr.children[i].barrage[j].content,
                    appear_count:
                      editpo_arr.children[i].barrage[j].appear_count,
                  });
                }
              }
            }
          }
        }
        if (editdi_arr != "") {
          this.discountsList= []
          this.discountsList.push({
            script_type: editdi_arr.script_type,
            action: editdi_arr.action,
            script_content: editdi_arr.script_content, //脚本内容
            correct_keyword: editdi_arr.correct_keyword, //正确关键词
            wrong_keyword: editdi_arr.wrong_keyword, //错误关键词
            time: Math.floor(editdi_arr.answer_time / 60), //分
            time1: Math.floor(editdi_arr.answer_time % 60), //秒
            answer_time: editdi_arr.answer_time, //传给后台时间总和
            change_rate: editdi_arr.change_rate, //转化率
            click_rate: editdi_arr.click_rate, //点击率
            lost_rate: editdi_arr.lost_rate, //流失率
            children: [],
          });
          if (editdi_arr.children) {
            for (let i = 0; i < editdi_arr.children.length; i++) {
              this.discountsList[0].children.push({
                action: editdi_arr.children[i].action,
                script_content: editdi_arr.children[i].script_content, //脚本内容
                correct_keyword: editdi_arr.children[i].correct_keyword, //正确关键词
                wrong_keyword: editdi_arr.children[i].wrong_keyword, //错误关键词
                time: Math.floor(editdi_arr.children[i].answer_time / 60), //分
                time1: Math.floor(editdi_arr.children[i].answer_time % 60), //秒
                answer_time: editdi_arr.children[i].answer_time, //传给后台时间总和
                change_rate: editdi_arr.children[i].change_rate, //转化率
                click_rate: editdi_arr.children[i].click_rate, //点击率
                lost_rate: editdi_arr.children[i].lost_rate, //流失率
                barrage: [],
              });
              if (editdi_arr.children[i].barrage) {
                for (let j = 0;j < editdi_arr.children[i].barrage.length;j++) {
                  this.discountsList[0].children[i].barrage.push({
                    id: editdi_arr.children[i].barrage[j].id,
                    content: editdi_arr.children[i].barrage[j].content,
                    appear_count:
                      editdi_arr.children[i].barrage[j].appear_count,
                  });
                }
              }
            }
          }
        }
        if (editgo_arr != "") {
          this.goodsIssueList=[]
          this.goodsIssueList.push({
            script_type: editgo_arr.script_type,
            action: editgo_arr.action,
            script_content: editgo_arr.script_content, //脚本内容
            correct_keyword: editgo_arr.correct_keyword, //正确关键词
            wrong_keyword: editgo_arr.wrong_keyword, //错误关键词
            time: Math.floor(editgo_arr.answer_time / 60), //分
            time1: Math.floor(editgo_arr.answer_time % 60), //秒
            answer_time: editgo_arr.answer_time, //传给后台时间总和
            change_rate: editgo_arr.change_rate, //转化率
            click_rate: editgo_arr.click_rate, //点击率
            lost_rate: editgo_arr.lost_rate, //流失率
            children: [],
          });
          if (editgo_arr.children) {
            for (let i = 0; i < editgo_arr.children.length; i++) {
              this.goodsIssueList[0].children.push({
                action: editgo_arr.children[i].action,
                script_content: editgo_arr.children[i].script_content, //脚本内容
                correct_keyword: editgo_arr.children[i].correct_keyword, //正确关键词
                wrong_keyword: editgo_arr.children[i].wrong_keyword, //错误关键词
                time: Math.floor(editgo_arr.children[i].answer_time / 60), //分
                time1: Math.floor(editgo_arr.children[i].answer_time % 60), //秒
                answer_time: editgo_arr.children[i].answer_time, //传给后台时间总和
                change_rate: editgo_arr.children[i].change_rate, //转化率
                click_rate: editgo_arr.children[i].click_rate, //点击率
                lost_rate: editgo_arr.children[i].lost_rate, //流失率
                barrage: [],
              });
              if (editgo_arr.children[i].barrage) {
                for (let j = 0;j < editgo_arr.children[i].barrage.length;j++) {
                  this.goodsIssueList[0].children[i].barrage.push({
                    id: editgo_arr.children[i].barrage[j].id,
                    content: editgo_arr.children[i].barrage[j].content,
                    appear_count:
                      editgo_arr.children[i].barrage[j].appear_count,
                  });
                }
              }
            }
          }
        }
      }
    },
    addVerbal() {
      this.explainList[0].children.push({
        id: this.cate_id++,
        script_content: "", //脚本内容
        correct_keyword: "", //正确关键词
        wrong_keyword: "", //错误关键词
        time: void 0, //分
        time1: void 0, //秒
        answer_time: "", //时间总和
        change_rate: "", //转化率
        click_rate: "", //点击率
        lost_rate: "", //流失率
        barrage: [],
      });
    },
    //商品讲解删除按钮
    VerbalDel() {
      this.$confirm(`删除后整个脚本类型包含所有话术将会一起删除，您确定要删除吗？`, "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "script-message-box",
        type: "warning",
      })
        .then(() => {
          this.explainList.splice(0, 1);
          this.explainShow = false;
          if (
            this.explainList == "" &&
            this.pointsList == "" &&
            this.discountsList == "" &&
            this.goodsIssueList == ""
          ) {
            this.img = 0;
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //产品卖点删除按钮
    pointsDel() {
      this.$confirm(`删除后整个脚本类型包含所有话术将会一起删除，您确定要删除吗？`, "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "script-message-box",
        type: "warning",
      })
        .then(() => {
           this.pointsList.splice(0, 1);
            this.pointsShow = false;
            if (
              this.explainList == "" &&
              this.pointsList == "" &&
              this.discountsList == "" &&
              this.goodsIssueList == ""
            ) {
              this.img = 0;
            }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //优惠券删除按钮
    DiscountsDel() {
      this.$confirm(`删除后整个脚本类型包含所有话术将会一起删除，您确定要删除吗？`, "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "script-message-box",
        type: "warning",
      })
        .then(() => {
          this.discountsList.splice(0, 1);
          this.discountsShow = false;
          if (
            this.explainList == "" &&
            this.pointsList == "" &&
            this.discountsList == "" &&
            this.goodsIssueList == ""
          ) {
            this.img = 0;
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //商品问答删除按钮
    goodsIssueDel() {
       this.$confirm(`删除后整个脚本类型包含所有话术将会一起删除，您确定要删除吗？`, "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "script-message-box",
        type: "warning",
      })
        .then(() => {
          this.goodsIssueList.splice(0, 1);
          this.goodsIssueShow = false;
          if (
            this.explainList == "" &&
            this.pointsList == "" &&
            this.discountsList == "" &&
            this.goodsIssueList == ""
          ) {
            this.img = 0;
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //产品卖点添加脚本话术按钮
    addpointsButton() {
      this.pointsList[0].children.push({
        id: this.cate_id++,
        script_content: "", //脚本内容
        correct_keyword: "", //正确关键词
        wrong_keyword: "", //错误关键词
        time: void 0, //分
        time1: void 0, //秒
        answer_time: "", //时间总和
        change_rate: "", //转化率
        click_rate: "", //点击率
        lost_rate: "", //流失率
        barrage: [],
      });
    },
    //优惠券添加脚本话术按钮
    addDiscountsButtom() {
      this.discountsList[0].children.push({
        id: this.cate_id++,
        script_content: "", //脚本内容
        correct_keyword: "", //正确关键词
        wrong_keyword: "", //错误关键词
        time: void 0, //分
        time1: void 0, //秒
        answer_time: "", //时间总和
        change_rate: "", //转化率
        click_rate: "", //点击率
        lost_rate: "", //流失率
        barrage: [],
      });
    },
    //商品问答添加脚本话术按钮
    addgoodsIssueButtom() {
      this.goodsIssueList[0].children.push({
        id: this.cate_id++,
        script_content: "", //脚本内容
        correct_keyword: "", //正确关键词
        wrong_keyword: "", //错误关键词
        time: void 0, //分
        time1: void 0, //秒
        answer_time: "", //时间总和
        change_rate: "", //转化率
        click_rate: "", //点击率
        lost_rate: "", //流失率
        barrage: [],
      });
    },

    //商品讲解添加
    addLevel(val, level) {
      if (level === 1) {
        this.class_id1 = val.id;
        val.children.push({
          id: this.cate_id++,
          script_content: "", //脚本内容
          correct_keyword: "", //正确关键词
          wrong_keyword: "", //错误关键词
          time: void 0, //分
          time1: void 0, //秒
          answer_time: "", //时间总和
          change_rate: "", //转化率
          click_rate: "", //点击率
          lost_rate: "", //流失率
          barrage: [],
        });
        //数据加载完显示滚动条
        this.$nextTick(() => {
          this.$refs.scro.update();
          this.$refs.bar.update();
        });
      }
      if (level === 2) {
        this.class_id2 = val.id;
        this.disabled = true;
        val.barrage.push({
          id: this.cate_id++,
          content: "", //弹幕内容
          appear_count: "", //出现次数
        });
        this.$nextTick(() => {
          this.$refs.scro.update();
        });
      }
    },
    //商品讲解删除表格
    delLevel1(index) {
      this.explainList.splice(index, 1);
      this.explainShow = false;
    },
    delLevel2(index2) {
      this.explainList.forEach((item) => {
        item.children.splice(index2, 1);
      });
    },
    delLevel3(index3, id) {
      this.explainList.forEach((item2) => {
        item2.children.forEach((item3) => {
          item3.barrage.forEach((subItem) => {
            if (subItem.id == id) {
              item3.barrage.splice(index3, 1);
            }
          });
        });
      });
    },

    //产品卖点添加
    addpoints(val, level) {
      if (level === 1) {
        this.class_id1 = val.id;
        val.children.push({
          id: this.cate_id++,
          script_content: "", //脚本内容
          correct_keyword: "", //正确关键词
          wrong_keyword: "", //错误关键词
          time: void 0, //分
          time1: void 0, //秒
          answer_time: "", //时间总和
          change_rate: "", //转化率
          click_rate: "", //点击率
          lost_rate: "", //流失率
          barrage: [],
        });
        //数据加载完显示滚动条
        this.$nextTick(() => {
          this.$refs.scropoints.update();
        });
        // console.log(val.children)
      }
      if (level === 2) {
        this.class_id2 = val.id;
        val.barrage.push({
          id: this.cate_id++,
          content: "", //脚本内容
          appear_count: "", //出现次数
        });
        this.$nextTick(() => {
          this.$refs.scropoints.update();
        });
      }
    },
    //产品卖点删除表格
    delpoints1(index) {
      this.pointsList.splice(index, 1);
      this.pointsShow = false;
    },
    delpoints2(index2) {
      this.pointsList.forEach((item) => {
        item.children.splice(index2, 1);
      });
    },
    delpoints3(index3, id) {
      this.pointsList.forEach((item2) => {
        item2.children.forEach((item3) => {
          item3.barrage.forEach((subItem) => {
            if (subItem.id == id) {
              item3.barrage.splice(index3, 1);
            }
          });
        });
      });
    },

    //优惠券添加
    addDiscounts(val, level) {
      if (level === 1) {
        this.class_id1 = val.id;
        val.children.push({
          id: this.cate_id++,
          script_content: "", //脚本内容
          correct_keyword: "", //正确关键词
          wrong_keyword: "", //错误关键词
          time: void 0, //分
          time1: void 0, //秒
          answer_time: "", //时间总和
          change_rate: "", //转化率
          click_rate: "", //点击率
          lost_rate: "", //流失率
          barrage: [],
        });
        //数据加载完显示滚动条
        this.$nextTick(() => {
          this.$refs.scrodiscounts.update();
        });
        // console.log(val.children)
      }
      if (level === 2) {
        this.class_id2 = val.id;
        val.barrage.push({
          id: this.cate_id++,
          content: "", //脚本内容
          appear_count: "", //出现次数
        });
        this.$nextTick(() => {
          this.$refs.scrodiscounts.update();
        });
      }
    },
    //优惠券删除表格
    delDiscounts1(index) {
      this.discountsList.splice(index, 1);
      this.discountsShow = false;
    },
    delDiscounts2(index2) {
      this.discountsList.forEach((item) => {
        item.children.splice(index2, 1);
      });
    },
    delDiscounts3(index3, id) {
      this.discountsList.forEach((item2) => {
        item2.children.forEach((item3) => {
          item3.barrage.forEach((subItem) => {
            if (subItem.id == id) {
              item3.barrage.splice(index3, 1);
            }
          });
        });
      });
    },

    //商品问题添加
    addgoodsIssue(val, level) {
      if (level === 1) {
        this.class_id1 = val.id;
        val.children.push({
          id: this.cate_id++,
          script_content: "", //脚本内容
          correct_keyword: "", //正确关键词
          wrong_keyword: "", //错误关键词
          time: void 0, //分
          time1: void 0, //秒
          answer_time: "", //时间总和
          change_rate: "", //转化率
          click_rate: "", //点击率
          lost_rate: "", //流失率
          barrage: [],
        });
        //数据加载完显示滚动条
        this.$nextTick(() => {
          this.$refs.scrogoodsIssue.update();
        });
        // console.log(val.children)
      }
      if (level === 2) {
        this.class_id2 = val.id;
        val.barrage.push({
          id: this.cate_id++,
          content: "", //脚本内容
          appear_count: "", //出现次数
        });
        this.$nextTick(() => {
          this.$refs.scrogoodsIssue.update();
        });
      }
    },
    //商品问题删除表格
    delGoodsIssue1(index) {
      this.goodsIssueList.splice(index, 1);
      this.goodsIssueShow = false;
    },
    delGoodsIssue2(index2) {
      this.goodsIssueList.forEach((item) => {
        item.children.splice(index2, 1);
      });
    },
    delGoodsIssue3(index3, id) {
      this.goodsIssueList.forEach((item2) => {
        item2.children.forEach((item3) => {
          item3.barrage.forEach((subItem) => {
            if (subItem.id == id) {
              item3.barrage.splice(index3, 1);
            }
          });
        });
      });
    },

    //添加脚本类型
    addType() {
      this.dialogAddClassify = true;
      this.classifyTitleType = "创建脚本类型";
    },

    //添加脚本类型确定
    addToForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.addForm.name == 1) {
            this.img = 1;
            this.explainShow = true;
            if (this.explainList == "") {
              this.explainList.push({
                script_type: 1,
                action: 1,
                script_content: "", //脚本内容
                correct_keyword: "", //正确关键词
                wrong_keyword: "", //错误关键词
                time: void 0, //分
                time1: void 0, //秒
                answer_time: "", //传给后台时间总和
                change_rate: "", //转化率
                click_rate: "", //点击率
                lost_rate: "", //流失率
                children: [],
              });
            } else {
              this.$message.warning("商品讲解已添加");
            }

            this.dialogAddClassify = false;
          } else if (this.addForm.name == 2) {
            this.img = 1;
            this.pointsShow = true;
            if (this.pointsList == "") {
              this.pointsList.push({
                script_type: 4,
                action: 0,
                script_content: "", //脚本内容
                correct_keyword: "", //正确关键词
                wrong_keyword: "", //错误关键词
                time: void 0, //分
                time1: void 0, //秒
                answer_time: "", //传给后台时间总和
                change_rate: "", //转化率
                click_rate: "", //点击率
                lost_rate: "", //流失率
                children: [],
              });
            } else {
              this.$message.warning("产品卖点介绍已添加");
            }

            this.dialogAddClassify = false;
          } else if (this.addForm.name == 3) {
            this.img = 1;
            this.discountsShow = true;
            if (this.discountsList == "") {
              this.discountsList.push({
                script_type: 2,
                action: 2,
                script_content: "", //脚本内容
                correct_keyword: "", //正确关键词
                wrong_keyword: "", //错误关键词
                time: void 0, //分
                time1: void 0, //秒
                answer_time: "", //传给后台时间总和
                change_rate: "", //转化率
                click_rate: "", //点击率
                lost_rate: "", //流失率
                children: [],
              });
            } else {
              this.$message.warning("优惠券已添加");
            }
            this.dialogAddClassify = false;
          } else if (this.addForm.name == 4) {
            this.img = 1;
            this.goodsIssueShow = true;
            if (this.goodsIssueList == "") {
              this.goodsIssueList.push({
                script_type: 3,
                action: 0,
                script_content: "", //脚本内容
                correct_keyword: "", //正确关键词
                wrong_keyword: "", //错误关键词
                time: void 0, //分
                time1: void 0, //秒
                answer_time: "", //传给后台时间总和
                change_rate: "", //转化率
                click_rate: "", //点击率
                lost_rate: "", //流失率
                children: [],
              });
            } else {
              this.$message.warning("商品问答已添加");
            }
            this.dialogAddClassify = false;
          }
        }
      });
    },
    //弹窗重置
    resetForm() {
      this.$nextTick(() => {
        this.$refs.addForm.resetFields();
      });
    },

    // 返回上一步
    backTo() {
      if(this.$route.query.id){
          this.$router.push({
        path: "/liveStreaming/newComManagementAnswers",
        query: { 
          id: this.$route.query.id,
        },
      });
      }else{
        this.$router.push({
        path: "/liveStreaming/newComManagementAnswers",
        query: { 
          answersListTable:this.issueList,
        },
      });
      }
     
    },
    //下一步
    next() {
      if (this.explainList != "") {
        for (let i = 0; i < this.explainList.length; i++) {
          if (
            this.explainList[i].time * 60 + this.explainList[i].time1 >
            3600
          ) {
            this.$message.warning("商品讲解回答间隔时间不能大于60分钟");
            return
          } else {
            this.explainList[i].answer_time =
              this.explainList[i].time * 60 + this.explainList[i].time1;
          }
          if (this.explainList[i].children != "") {
            for (let j = 0; j < this.explainList[i].children.length; j++) {
              if (
                this.explainList[i].children[j].time * 60 +
                  this.explainList[i].children[j].time1 >
                3600
              ) {
                this.$message.warning("商品讲解子类回答间隔时间不能大于60分钟");
                return
              } else {
                this.explainList[i].children[j].answer_time =
                  this.explainList[i].children[j].time * 60 +
                  this.explainList[i].children[j].time1;
              }
            }
          }
        }
        this.goods_script.push(this.explainList[0]);
      }
      if (this.pointsList != "") {
        for (let i = 0; i < this.pointsList.length; i++) {
          if (this.pointsList[i].time * 60 + this.pointsList[i].time1 > 3600) {
            this.$message.warning("产品卖点回答间隔时间不能大于60分钟");
            return
          } else {
            this.pointsList[i].answer_time =
              this.pointsList[i].time * 60 + this.pointsList[i].time1;
          }
          if (this.pointsList[i].children != "") {
            for (let j = 0; j < this.pointsList[i].children.length; j++) {
              if (
                this.pointsList[i].children[j].time * 60 +
                  this.pointsList[i].children[j].time1 >
                3600
              ) {
                this.$message.warning("产品卖点子类回答间隔时间不能大于60");
                return
              } else {
                this.pointsList[i].children[j].answer_time =
                  this.pointsList[i].children[j].time * 60 +
                  this.pointsList[i].children[j].time1;
              }
            }
          }
        }
        this.goods_script.push(this.pointsList[0]);
      }
      if (this.discountsList != "") {
        for (let i = 0; i < this.discountsList.length; i++) {
          if (
            this.discountsList[i].time * 60 + this.discountsList[i].time1 >
            3600
          ) {
            this.$message.warning("优惠券回答间隔时间不能大于60分钟");
            return
          } else {
            this.discountsList[i].answer_time =
              this.discountsList[i].time * 60 + this.discountsList[i].time1;
          }
          if (this.discountsList[i].children != "") {
            for (let j = 0; j < this.discountsList[i].children.length; j++) {
              if (
                this.discountsList[i].children[j].time * 60 +
                  this.discountsList[i].children[j].time1 >
                3600
              ) {
                this.$message.warning("优惠券子类回答间隔时间不能大于60分钟");
                return
              } else {
                this.discountsList[i].children[j].answer_time =
                  this.discountsList[i].children[j].time * 60 +
                  this.discountsList[i].children[j].time1;
              }
            }
          }
        }
        this.goods_script.push(this.discountsList[0]);
      }
      if (this.goodsIssueList != "") {
        for (let i = 0; i < this.goodsIssueList.length; i++) {
          if (
            this.goodsIssueList[i].time * 60 + this.goodsIssueList[i].time1 >
            3600
          ) {
            this.$message.warning("商品问答回答间隔时间不能大于60分钟");
            return
          } else {
            this.goodsIssueList[i].answer_time =
              this.goodsIssueList[i].time * 60 + this.goodsIssueList[i].time1;
          }
          if (this.goodsIssueList[i].children != "") {
            for (let j = 0; j < this.goodsIssueList[i].children.length; j++) {
              if (
                this.goodsIssueList[i].children[j].time * 60 +
                  this.goodsIssueList[i].children[j].time1 >
                3600
              ) {
                this.$message.warning("商品问答子类回答间隔时间不能大于60分钟");
                return
              } else {
                this.goodsIssueList[i].children[j].answer_time =
                  this.goodsIssueList[i].children[j].time * 60 +
                  this.goodsIssueList[i].children[j].time1;
              }
            }
          }
        }

        this.goods_script.push(this.goodsIssueList[0]);
      }
      //问题表格时间计算
      for (let i = 0; i < this.issueList.length; i++) {
        if (this.issueList[i].issuetime * 60 + this.issueList[i].issuetime1 >3600)
        {
          console.log(this.issueList[i].issuetime * 60 + this.issueList[i].issuetime1,'888')
          this.$message.warning("问题间隔时间不能大于60分钟");
          return
        } else {
          this.issueList[i].answer_time =
            this.issueList[i].issuetime * 60 + this.issueList[i].issuetime1;
        }
      }

      //页面数组数据
      this.goods_scriptArr = []; //去重复后的新数组
      this.goods_scriptArr = this.goods_script.filter(
        (element, index, self) => {
          return self.indexOf(element) === index;
        }
      );


      for(let i=0;i<this.issueList.length;i++){
        this.issueList[i].answer_time = (this.issueList[i].issuetime*60)+this.issueList[i].issuetime1
      }


      let params = {
        id: this.sum_id,
        goods_info: JSON.parse(localStorage.getItem("message")), //商品信息
        goods_data: JSON.parse(localStorage.getItem("create")), //近30天数据
        goods_template: JSON.parse(localStorage.getItem("templateDate")), //模板数据
        goods_coupon: JSON.parse(localStorage.getItem("conponDate")), //优惠券
        goods_question: this.issueList, //商品问答
        goods_script: this.goods_scriptArr, //脚本分类内容
        before_create_goods_data: JSON.parse(
          localStorage.getItem("create_goods_data")
        ),
      };
      save(params)
        .then((res) => {
          if (res.code == 200) {
            this.$router.push({
              path: "/liveStreaming/newComManagementSucceed",
              query: { id: this.$route.query.id },
            });
          }
        })
        .catch((err) => {
          console.error("err", err);
          this.goods_script.splice(0, this.goods_script.length);
        });
    },
  },
};
</script>


<style lang="scss" scoped>
.main-wrapper {
  height: 80%;
  padding: 0 30px 0 10px;
  .title {
    text-align: center;
    padding-bottom: 20px;
    .titleData {
      line-height: 40px;
    }
  }
  .el-scrollbar {
    height: 100%;
    ::v-deep .el-scrollbar__wrap {
      height: 100%;
      overflow-x: hidden;
      .el-scrollbar__view {
        height: 100%;
      }
    }
  }
  .del {
    color: #ff3e6c;
    border: 1px solid #ff3e6c;
  }
  .footer-button {
    text-align: center;
    padding-top: 15px;
  }
 
  .explain {
    .el-scrollbar {
      // height: 100%;
      max-height: 300px;
      min-height: 20%;
      padding-bottom: 10px;
      ::v-deep .el-scrollbar__wrap {
        max-height: 300px;
        min-height: 80px;
        overflow-x: hidden;
        .el-scrollbar__view {
          max-height: 300px;
          min-height: 80px;
        }
      }
    }
    .foot-buttom {
      text-align: right;
      padding-top: 10px;
      ::v-deep .del:focus,
      .del:hover {
        background-color: #fef0f0;
      }
    }
  }
  .no-data-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .no-data-font {
      display: flex;
      justify-content: center;
      font-size: 14px;
      font-weight: 450;
      color: #a8a8b8;
    }
  }

  .explain {
    // width: 1595px;
    background: #ffffff;
    border-radius: 2px;
    .explain-header {
      display: flex;
      align-items: center;
      height: 60px;
      background: #f0f3fc;
      box-sizing: border-box;
    }
    .item {
      display: flex;
      border: 2px solid #f7f8fc;
      .explain-body-item {
        display: flex;
        align-items: center;
        min-height: 64px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}
</style>